import React, { Component } from 'react'
import { connect } from 'react-redux'
import autobind from 'react-autobind'
import _ from 'lodash'
import cookies from 'js-cookie'
import store from '../redux/index'
import {
  dispatchSelectImporterOfRecord,
  dispatchLoadingActiveClassificationsData,
  dispatchSetActiveClassificationRecords,
  dispatchStoreClassificationsForSelectedIor,
  dispatchSelectedAccount
} from '../redux/classification/actions'
import { getAllAccounts } from '../lib/accounts/accountUtils'
import { getClassifications } from '../lib/classe/classeService'
import { Select } from '@cimpress/react-components'

const IOR_SELECTION_COOKIE_KEY = 'ior_selection'

export async function getIorFromCookie() {
  const iorFromCookie = cookies.get(IOR_SELECTION_COOKIE_KEY)
  const importersOfRecord = store.getState().classification.importersOfRecord
  let ior

  if (importersOfRecord.length === 1) {
    ior = importersOfRecord[0] // Ignore everything and set ior to the only available ior.
  } else {
    // Set from cookie or force selection.
    if (
      !iorFromCookie ||
      !iorAccessibleToUser(importersOfRecord, iorFromCookie)
    )
      return

    ior = importersOfRecord.find((loc) => loc.id === iorFromCookie)
  }

  await setClassificationsBasedOnIor(ior)
}

function iorAccessibleToUser(importersOfRecord, iorFromCookie) {
  if (importersOfRecord && importersOfRecord.length > 0) {
    return importersOfRecord.find((loc) => loc.id === iorFromCookie)
  } else {
    return false
  }
}

async function setClassificationsBasedOnIor(ior) {
  dispatchSelectImporterOfRecord(ior)
  const state = store.getState().classification
  if (!state.isVistaprintIor) {
    dispatchLoadingActiveClassificationsData(true)
    const classeResponse = await getClassifications(ior.id)
    dispatchSetActiveClassificationRecords(classeResponse)
    dispatchLoadingActiveClassificationsData(false)
    dispatchStoreClassificationsForSelectedIor(classeResponse)
  } else {
    if (!state.accounts.length) await getAllAccounts(ior)
  }
}

export class IorSelect extends Component {
  constructor(props) {
    super(props)
    autobind(this)
  }

  async setIor(importerOfRecordOption) {
    const ior = this.props.importersOfRecord.find(
      (loc) => loc.id === importerOfRecordOption.value
    )
    cookies.set(IOR_SELECTION_COOKIE_KEY, importerOfRecordOption.value)
    dispatchSelectedAccount(undefined)
    await setClassificationsBasedOnIor(ior)
  }

  render() {
    if (
      !(this.props.importersOfRecord && this.props.importersOfRecord.length > 0)
    ) {
      return null
    }
    if (this.props.importersOfRecord.length === 1) {
      return (
        <label className="ior-select-label">
          Location:&nbsp;
          <label id="iorName">{this.props.importersOfRecord[0].name}</label>
        </label>
      )
    }
    return (
      <Select
        isDisabled={this.props.loadingActiveClassificationsData}
        label={'Importer of Record'}
        id={'iorSelect'}
        name={'iorSelect'}
        placeholder={
          this.props.selectedIor.value
            ? undefined
            : 'Please select an Importer of Record'
        }
        value={
          this.props.selectedIor.value ? this.props.selectedIor : undefined
        }
        options={this.props.iorOptions}
        onChange={this.setIor}
        containerClassName={'ior-select-control'}
      />
    )
  }
}

export default connect((state) => {
  let iorOptions = []
  let selectedIor = {}
  if (
    state.classification.importersOfRecord &&
    state.classification.importersOfRecord.length > 0
  ) {
    iorOptions = _.sortBy(
      state.classification.importersOfRecord.map((importerOfRecord) => {
        return {
          value: importerOfRecord.id,
          label: importerOfRecord.name
        }
      }),
      'label'
    )
  }
  if (state.classification.selectedImporterOfRecord) {
    selectedIor = {
      value: state.classification.selectedImporterOfRecord.id,
      label: state.classification.selectedImporterOfRecord.name
    }
  }
  return {
    importersOfRecord: state.classification.importersOfRecord,
    iorOptions,
    selectedIor,
    loadingActiveClassificationsData:
      state.classification.loadingActiveClassificationsData,
    activeClassificationsRecords:
      state.classification.activeClassificationsRecords
  }
})(IorSelect)
