import { makeAuthenticatedRequest } from '../authentication/authenticationProxy'
import { COAM_BASEURL } from '../../app-config.json'
import store from '../../redux/index'

export async function getPermissions() {
  const {
    profile: { sub: principal }
  } = store.getState().auth

  // Its just one entry for now but we may want to do something more elaborate later so useful code to preserve from classe.
  const coamResources = [
    {
      resourceName: 'importers-of-record',
      permissions: [
        'read:customs-classification',
        'create:customs-classification',
        'create:customs-classification-requests'
      ]
    }
  ]
  const resourceTypes = coamResources.map((resource) => resource.resourceName)
  const permissionFilters = coamResources
    .map(({ resourceName, permissions }) => {
      if (permissions && permissions.length > 0) {
        return `${resourceName}:${permissions.join('|')}`
      }
      return ''
    })
    .filter((x) => x)
    .join(',')

  const permissions = await makeAuthenticatedRequest({
    url: `${COAM_BASEURL}/v1/principals/${principal}/permissions${
      resourceTypes.length > 0
        ? `?resourceTypes=${resourceTypes.join(',')}`
        : ``
    }${
      permissionFilters !== '' ? `&permissionFilter=${permissionFilters}` : ''
    }`
  })

  store.dispatch({ type: 'SAVE_PERMISSIONS', permissions })
}

export function insist(permission, type, resource) {
  const allowedIds = allowed(permission, type)
  const isAllowed = allowedIds.some(function (id) {
    return id === '*' || id === resource
  })

  return isAllowed
}

export function writeAllowed() {
  const ior = store.getState().classification.selectedImporterOfRecord || ''
  if (ior.submissionType === 'classification')
    return insist(
      'create:customs-classification',
      'importers-of-record',
      ior.id
    )
  else
    return insist(
      'create:customs-classification-requests',
      'importers-of-record',
      ior.id
    )
}

export function readAllowed() {
  const ior = store.getState().classification.selectedImporterOfRecord || ''
  return insist('read:customs-classification', 'importers-of-record', ior.id)
}

function allowed(permission, type) {
  const { permissions } = store.getState().auth

  const items = (permissions[type] || [])
    .filter(function (item) {
      return item.permissions.indexOf(permission) !== -1
    })
    .map(function (item) {
      return item.identifier
    })

  if (items.indexOf('*') !== -1) {
    return ['*']
  }
  return items
}
