import store from '../index'

export const dispatchClassifications = (classifications) => {
  store.dispatch({ type: 'CLASSIFICATIONS', classifications })
}

export const dispatchFile = (file) => {
  store.dispatch({ type: 'FILE', file })
}

export const dispatchImportersOfRecord = (importersOfRecord) => {
  store.dispatch({ type: 'IMPORTERS_OF_RECORD', importersOfRecord })
}

export const dispatchSelectImporterOfRecord = (selectedImporterOfRecord) => {
  store.dispatch({
    type: 'SELECT_IMPORTER_OF_RECORD',
    selectedImporterOfRecord
  })
}

export const dispatchLoadingActiveClassificationsData = (
  loadingActiveClassificationsData
) => {
  store.dispatch({
    type: 'SET_LOADING_ACTIVE_CLASSIFICATION_DATA',
    loadingActiveClassificationsData
  })
}

export const dispatchSetActiveClassificationRecords = (classeResponse) => {
  store.dispatch({
    type: 'SET_ACTIVE_CLASSIFICATION_RECORDS',
    classeResponse
  })
}

export const dispatchSelectedProduct = (selected, selectedClassification) => {
  store.dispatch({
    type: 'SET_SELECTED_PRODUCT',
    selected,
    selectedClassification
  })
}

export const dispatchSetHoveredRow = (hoveredRow) => {
  store.dispatch({
    type: 'SET_SELECTED_HOVERED_ROW_PRODUCT_TO_DISPLAY',
    hoveredRow
  })
}

export const dispatchClassificationDetails = (
  htsData,
  productLink,
  manufacturerProductLink
) => {
  store.dispatch({
    type: 'SET_CLASSIFICATION_DETAIL_TO_DISPLAY',
    htsData,
    productLink,
    manufacturerProductLink
  })
}

export const dispatchSelectedAccount = (selectedAccount) => {
  store.dispatch({
    type: 'SET_SELECTED_ACCOUNT',
    selectedAccount
  })
}

export const dispatchAccounts = (accounts) => {
  store.dispatch({ type: 'SET_ACCOUNT_DATA_TO_DISPLAY', accounts })
}

export const dispatchStoreClassificationsForSelectedIor = (data) => {
  store.dispatch({ type: 'STORE_CLASSIFICATIONS_FOR_SELECTED_IOR', data })
}
